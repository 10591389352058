import ParseUrl from "./lib/ParseUrl";
import localforage from "localforage";
import { isTokenExpired } from "./lib/JWTool";

const { apiUrl, taskId } = ParseUrl();
const appSelector = "root";

let elem = document.getElementById( appSelector);

localforage.config({
					   driver: localforage.INDEXEDDB,
					   name: "FMA",
					   version: 1.0,
					   storeName: "appData"
				   });

const getConfigForComponent = (data, component) =>
	data.wedat.views.find((view) => view.component === component);

/**
 * Due to bug in Simone Plugin
 */
const cleanup = () => {
	elem.remove();
	let htmlDivElement = document.createElement("div");
	htmlDivElement.setAttribute("id", "root");

	document.body.appendChild(htmlDivElement);
	elem = document.getElementById( appSelector);
};

const TOKEN_UUID="5d8z6963"

const checkCredentials = async () => {
	const credentials = await localforage.getItem( taskId + TOKEN_UUID );
	return !!credentials;
};

export {
	localforage,
	apiUrl,
	taskId,
	appSelector,
	elem,
	cleanup,
	getConfigForComponent,
	TOKEN_UUID,
	checkCredentials
};
